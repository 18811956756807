import * as React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const Image = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  '& .participatingLogo': {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));
function ImageDisplay({ data, height = null }) {
  return (
    <Image>
      <Box>
        { data?.gatsbyImageData && (
          <GatsbyImage image={height ? Object.assign(data?.gatsbyImageData, { height, width: height }) : data?.gatsbyImageData} objectFit='contain' />
        ) }
        { !data?.gatsbyImageData && (
          <img src={data?.file?.url} alt='logo' className='participatingLogo' height={height} />
        ) }
      </Box>
    </Image>
  );
}

ImageDisplay.propTypes = {
  height: PropTypes.number
};

ImageDisplay.defaultProps = {
  height: null
};

export default ImageDisplay;
